import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { voluntaryOptOut } from '../../../api/session';
import { useSession } from '../../../session/hooks/useSession';
import { DeviceTypes } from '../../../types';
import { InfoPhase, PhaseResponse } from '../../../types/phase';
import { getIcon } from '../../../utility/icon';
import Modal from '../../../utility/modal';
import { getOnDemandCustomElementProps, screenUtils } from '../../../utility/screenUtils';
import ShowOnDevice from '../../../utility/showOnDevice';

import styles from './index.module.css';

interface Props {
  endResult: boolean;
  isPrimaryLocation: boolean;
  onOptOut: (res: PhaseResponse) => void;
  onMobileClick: () => void;
}

const ManualOptOut: React.FC<Props> = ({ onOptOut, endResult = false, isPrimaryLocation, onMobileClick }) => {
  const { session } = useSession();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const isMobile = screenUtils.DeviceIsMobile();

  useEffect(() => {
    let subscribed = true;
    const optOut = async () => {
      if (session?.sessionToken) {
        await voluntaryOptOut(session?.sessionToken)
          .then((res: PhaseResponse) => {
            if (subscribed) onOptOut(res);
          })
          .finally(() => {
            if (subscribed) {
              setLoading(false);
              setModalOpen(false);
            }
          });
      }
    };
    if (loading) {
      optOut();
    }
    return () => {
      subscribed = false;
    };
  }, [loading, onOptOut, session?.sessionToken]);

  let endResultPhase = session?.payload as InfoPhase;

  if (endResultPhase?.showContinueButton === false) return null;
  if (session?.onDemandConfig.allowManualOptOut === false) return null;
  if (endResult) return null;
  //Missing lead will be created before OptOut.

  return (
    <>
      <ShowOnDevice showOn={DeviceTypes.Desktop}>
        <Button
          variant="outlined"
          onClick={() => setModalOpen(true)}
          id="needHelpButton"
          className={styles.needHelpButton}
          endIcon={<FontAwesomeIcon className="button-icon" icon={getIcon('faChevronRight')} />}
          {...getOnDemandCustomElementProps('btnNeedHelp', 'I Need Help')}>
          I Need Help
        </Button>
      </ShowOnDevice>
      <ShowOnDevice showOn={DeviceTypes.Mobile}>
        <Link
          fontSize={'1.2rem'}
          color="primary"
          role="button"
          id="needHelpButton"
          onClick={() => {
            setModalOpen(true);
            onMobileClick();
          }}
          {...getOnDemandCustomElementProps('btnNeedHelp', 'I Need Help')}>
          I Need Help
        </Link>
      </ShowOnDevice>
      <Modal
        show={modalOpen}
        fullScreen={isMobile}
        size="md"
        closeModal={() => setModalOpen(false)}
        hideTitle
        onlyCloseButton
        title="">
        <Box className={styles.optOutContainer}>
          <h1>Are you sure you want to leave?</h1>
          {isPrimaryLocation ? 
            <>
              <Box className={styles.subTitle} color="text.secondary">
                Clicking the button below confirms that you want to abandon the self-boarding application and prefer to speak to a representative directly.
              </Box>
              <Box className={styles.subTitle} color="text.secondary">
                Please note, you will not be able to access your digital application after confirming.
              </Box>
            </>:
            <>
              <Box className={styles.subTitle} color="text.secondary">
                Clicking the button below confirms that you want to abandon the self-boarding application for the in-progress location and prefer to speak to a representative directly.
              </Box>
              <Box className={styles.subTitle} color="text.secondary">
                Please note, you will not be able to self-board any additional locations after confirming.
              </Box>
            </>
          }
          <Box className={styles.buttonContainer}>
            <Button variant="outlined" onClick={() => setModalOpen(false)}>
              Return to application
            </Button>
            <LoadingButton loading={loading} variant="contained" onClick={() => setLoading(true)}>
              {!loading && 'Confirm'}
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default ManualOptOut;
