import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { ThrottlingState, useThrottling } from '../../hooks/useThrottling';
import { OneXThemePalette } from '../../types/enums/theme';
import { CaptchaType } from '../../types/phase';
import useCaptcha, { CaptchaVerify } from '../../utility/captcha';
import { getIcon } from '../../utility/icon';
import { getOnDemandCustomElementProps } from '../../utility/screenUtils';

import styles from './index.module.css';

interface Props {
  children?: React.ReactNode;
  captchaType: CaptchaType | undefined;
  isLoadingContinue: boolean;
  isLoadingBack: boolean;
  isLoadingLink?: boolean;
  isDisabledContinue?: boolean;
  isDisabledBack?: boolean;
  isDisabledLink?: boolean;
  showBack: boolean;
  showContinue: boolean;
  showLink?: boolean;
  backButtonText: string;
  continueButtonText: string;
  continueButtonVariant?: 'text' | 'outlined' | 'contained';
  continueTestId?: string;
  linkButtonText?: string;
  linkButtonTestId?: string;
  backTestId?: string;
  width?: string;
  buttonFooterIcons?: ButtonFooterIconProps;
  onBack: () => void;
  onContinue: () => void;
  onLinkClick?: () => void;
}

type ButtonIconProps = {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
};

type ButtonFooterIconProps = {
  link?: ButtonIconProps;
  continue?: ButtonIconProps;
  back?: ButtonIconProps;
};

const ButtonFooter: React.FC<Props> = ({
  children,
  captchaType = CaptchaType.SILENT,
  isLoadingContinue,
  isLoadingBack,
  isLoadingLink,
  isDisabledContinue,
  isDisabledBack,
  isDisabledLink = false,
  showBack,
  showContinue,
  showLink,
  backButtonText,
  continueButtonText,
  continueButtonVariant = 'contained',
  continueTestId,
  linkButtonText,
  linkButtonTestId,
  backTestId,
  width = '75%',
  buttonFooterIcons,
  onBack,
  onContinue,
  onLinkClick,
}) => {
  const theme = useTheme<OneXThemePalette>();
  const { error, handleContinue } = useCaptcha(captchaType, onContinue);
  const { error: linkError, handleContinue: handleLink } = useCaptcha(
    captchaType,
    onLinkClick ? onLinkClick : onContinue,
  );
  const { throttling, handleThrottledClick } = useThrottling();

  const continueSx = {
    visibility: showContinue ? 'visible' : 'hidden',
  };
  const backSx = {
    visibility: showBack ? 'visible' : 'hidden',
    order: showBack ? 2 : 3,
  };

  const isLoadingSomething = isLoadingContinue || isLoadingBack || isLoadingLink || throttling !== ThrottlingState.FREE;

  const handleContinueClick = () => {
    handleThrottledClick(ThrottlingState.CONTINUE, handleContinue);
  };

  const handleBackClick = () => {
    handleThrottledClick(ThrottlingState.BACK, onBack);
  };

  const handleLinkClick = () => {
    handleThrottledClick(ThrottlingState.LINK, handleLink);
  };

  if (!buttonFooterIcons) {
    buttonFooterIcons = {
      continue: {
        endIcon: <FontAwesomeIcon className="button-icon" icon={getIcon('faChevronRight')} />,
      },
      back: {
        startIcon: <FontAwesomeIcon className="button-icon" icon={getIcon('faChevronLeft')} />,
      },
      link: {
        endIcon:
          isLoadingLink || throttling === ThrottlingState.LINK ? (
            <CircularProgress
              data-testid={`${linkButtonTestId ?? 'btnLink'}Loading`}
              sx={{ alignSelf: 'center' }}
              size={20}
              color="primary"
            />
          ) : (
            <FontAwesomeIcon className="button-icon" icon={getIcon('faChevronRight')} />
          ),
      },
    };
  }

  return (
    <>
      <CaptchaVerify captchaType={captchaType} errorMessage={error || linkError} />
      <Box className={styles.buttonFooter} sx={{ width: width }} data-testid={'buttonFooter'}>
        <Box className={styles.continueButtons}>
          {showLink && (
            <Button
              component={Link}
              color="primary"
              variant="outlined"
              id="btnLink"
              data-testid={linkButtonTestId ?? 'btnLink'}
              onClick={handleLinkClick}
              sx={{
                marginRight: '1rem',
                border: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                  border: 0,
                },
                '&:active': {
                  backgroundColor: 'transparent',
                  border: 0,
                },
              }}
              {...buttonFooterIcons.link}>
              {linkButtonText}
            </Button>
          )}
          <LoadingButton
            variant={continueButtonVariant}
            color="primary"
            id="btnContinue"
            data-testid={continueTestId ?? 'btnContinue'}
            sx={
              isLoadingContinue || throttling === ThrottlingState.CONTINUE
                ? {
                    ...continueSx,
                    backgroundColor: theme.palette.primary.inputBorderDisabled + ' !important',
                  }
                : continueSx
            }
            disabled={isDisabledContinue || isLoadingSomething}
            loading={isLoadingContinue || throttling === ThrottlingState.CONTINUE}
            onClick={handleContinueClick}
            {...buttonFooterIcons.continue}
            {...getOnDemandCustomElementProps('btnContinue', continueButtonText)}>
            {!(isLoadingContinue || throttling === ThrottlingState.CONTINUE) && continueButtonText}
          </LoadingButton>
        </Box>
        {children}
        <LoadingButton
          variant="outlined"
          color="primary"
          id="btnBack"
          data-testid={backTestId ?? 'btnBack'}
          disabled={isDisabledBack || isLoadingSomething}
          loading={isLoadingBack || throttling === ThrottlingState.BACK}
          onClick={handleBackClick}
          sx={backSx}
          {...buttonFooterIcons.back}
          {...getOnDemandCustomElementProps('btnBack', backButtonText)}>
          {!(isLoadingBack || throttling === ThrottlingState.BACK) && backButtonText}
        </LoadingButton>
      </Box>
    </>
  );
};

export default ButtonFooter;
