import { useState } from 'react';

import { debounce } from 'lodash';

enum ThrottlingState {
  FREE = 'Free',
  CONTINUE = 'Continue',
  BACK = 'Back',
  LINK = 'Link',
}

type UseThrottlingModel = {
  throttling: ThrottlingState;
  handleThrottledClick: (action: ThrottlingState, callback: () => Promise<void> | void) => void;
};

const useThrottling = (): UseThrottlingModel => {
  const [throttling, setThrottling] = useState<ThrottlingState>(ThrottlingState.FREE);

  const handleThrottledClick = debounce(
    async (action: ThrottlingState, callback: () => Promise<void> | void) => {
      if (throttling === ThrottlingState.FREE) {
        setThrottling(action);
        await callback();
        setThrottling(ThrottlingState.FREE);
      }
    },
    1000,
    { leading: true, trailing: false },
  );

  return {
    throttling,
    handleThrottledClick,
  };
};

export { useThrottling, ThrottlingState };
export type { UseThrottlingModel };
